<template>
    <div v-if="show"
         class="fixed w-fit right-0 animation-scroll z-[800]"
         ref="popup"
         :class="posY"
    >
        <div @click.self="callToAction"
             class="relative overflow-hidden w-[19.5rem] m-4 pt-5 pb-8 px-2 z-40 flex flex-col justify-center items-center rounded-xl bg-forest shadow-2xl group cursor-pointer"
        >
            <icons-x-solid
                @click.prevent.stop="closePopUp"
                class="absolute cursor-pointer right-0 top-0 mr-1 mt-0.5 w-7 text-white hover:bg-grey5/20 rounded-full p-1">
            </icons-x-solid>

            <!--            <nuxt-img-->
            <!--                @click="callToAction"-->
            <!--                format="webp"-->
            <!--                src="/images/pop-up/martingale.png"-->
            <!--                alt="Les bonnes affaires sont de sortie"-->
            <!--                ratio="1"-->
            <!--                class="w-full z-10">-->
            <!--            </nuxt-img>-->

            <div class="z-10 flex flex-col gap-4">
                <div class="flex text-white pl-3 pr-4">
                    <div class="flex flex-col gap-2">
                        <div class="text-sm font-work font-semibold tracking-[6.5px] mb-2">
                            WEBINAIRE
                        </div>

                        <div class="font-berlingske text-3xl font-black leading-[28px] tracking-wide">
                            3 opportunités
                        </div>

                        <div class="font-berlingske text-3xl font-black leading-[28px] tracking-wide text-forest3">
                            Off Market
                        </div>

                        <div class="font-extrabold text-2xl leading-7 tracking-tight">
                            pour investir
                            <div>
                                en immobilier
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex items-center pl-5 -mt-24">
                    <nuxt-img
                        @click="callToAction"
                        format="webp"
                        src="/images/pop-up/offmarket.png"
                        alt="3 opportunités off market"
                        ratio="1"
                        class="w-7/12 grow z-10">
                    </nuxt-img>
                </div>

                <div class="font-bold text-forest3 text-base text-center">
                    Jeudi 26 septembre - 13h
                </div>
            </div>

            <c-button
                @click="callToAction"
                class="bg-tangerine text-white px-8 mt-6 !py-3 !font-semibold !text-lg z-30"
            >
                Je m’inscris au webinaire
            </c-button>
        </div>
    </div>
</template>

<script setup>
import {useUserStore} from "~/store/user";
import {watchOnce} from "@vueuse/core";
import useGtmTrackEvent from "~/composables/gtmTracking";

const router = useRouter();
const route = useRoute();
const storageShow = ref(false);

const posY = ref('mt-28');
const popup = ref();

const disabledOnPages = computed(() => {
    return [
        'connexion'
    ];
});

const show = computed(() => {
    return (
        storageShow.value === true &&
        useUserStore().isLoggedIn === false &&
        useNuxtApp().$isMobile.value === false &&
        useNuxtApp().$isTablette.value === false &&
        disabledOnPages.value.includes(route.name) === false
    );
});

onMounted(() => {
    storageShow.value = JSON.parse(localStorage.getItem('popUpShown') || 'true');
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', listener);
});

function callToAction() {
    closePopUp();
    // router.push({name: 'catalogue', query: {promos: 'true'}});
    window.open('https://app.livestorm.co/maslow/3-opportunites-off-market-pour-investir-en-immobilier?type=detailed', '_blank').focus();
}

function closePopUp() {
    localStorage.setItem('popUpShown', JSON.stringify(false));
    useGtmTrackEvent({
            event: 'click_popup',
            target: 'webinar-2609',
            creative: '09-2024'
        }
    );

    storageShow.value = false;
}

function addScrollListener() {
    window.addEventListener('scroll', listener);
}

function listener() {
    posY.value = window.scrollY > 0 ? 'mt-0' : 'mt-28';
}

watchOnce(() => popup.value, () => {
    if (show.value === true && getComputedStyle(popup.value).getPropertyValue('--active-listener') === '1') {
        addScrollListener();
    }
});
</script>

<style scoped lang="scss">
@supports (animation-timeline: scroll(y)) {
    .animation-scroll {
        animation: 200ms ease-in-out 0s smooth-margin;
        animation-timeline: scroll(y);
        transition: margin-top 200ms ease-in-out 0s;
    }

    @keyframes smooth-margin {
        0% {
            margin-top: 7rem;
        }

        10%, 100% {
            margin-top: 0;
        }
    }
}

@supports not (animation-timeline: scroll(y)) {
    .animation-scroll {
        --active-listener: 1;
    }
}
</style>
